/**
 * 存储localStorage
 */
export const setStore = (name, content) => {
  if (!name) return;
  if (typeof content !== 'string') {
    content = JSON.stringify(content);
  }
  window.localStorage.setItem(name, content);
}

/**
 * 获取localStorage
 */
export const getStore = name => {
  if (!name) return;
  var value = window.localStorage.getItem(name);
  if (value !== null) {
    try {
      value = JSON.parse(value);
    } catch (e) {
      value = value;
    }
  }
  return value;
}

/**
 * 删除localStorage
 */
export const removeStore = name => {
  if (!name) return;
  window.localStorage.removeItem(name);
}

/**
 * 让整数自动保留2位小数
 */
// export const returnFloat = value => {
//     var value=Math.round(parseFloat(value)*100)/100;
//     var xsd=value.toString().split(".");
//     if(xsd.length==1){
//         value=value.toString()+".00";
//         return value;
//     }
//     if(xsd.length>1){
//         if(xsd[1].length<2){
//             value=value.toString()+"0";
//         }
//         return value;
//     }
// }
/**
 * @param {date} 标准时间格式:Fri Nov 17 2017 09:26:23 GMT+0800 (中国标准时间)
 * @param {type} 类型
 *   type == 1 ---> "yyyy-mm-dd hh:MM:ss.fff"
 *   type == 2 ---> "yyyymmddhhMMss"
 *   type == '' ---> "yyyy-mm-dd hh:MM:ss"
 */
export const formatDate = (date, type) => {
  var year = date.getFullYear();//年
  var month = date.getMonth() + 1 < 10 ? "0" + (date.getMonth() + 1) : date.getMonth() + 1;//月
  var day = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();//日
  var hour = date.getHours() < 10 ? "0" + date.getHours() : date.getHours();//时
  var minutes = date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();//分
  var seconds = date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();//秒
  var milliseconds = date.getMilliseconds() < 10 ? "0" + date.getMilliseconds() : date.getMilliseconds() //毫秒
  if (type == 1) {
    return year + "-" + month + "-" + day + " " + hour + ":" + minutes + ":" + seconds + "." + milliseconds;
  } else if (type == 2) {
    return year + "" + month + "" + day + "" + hour + "" + minutes + "" + seconds;
  } else if (type == 3) {
    return year + "-" + month + "-" + day;
  } else {
    return year + "-" + month + "-" + day + " " + hour + ":" + minutes + ":" + seconds;
  }
}
/**
 * 时间转换：20150101010101 --> '2015-01-01 01:01:01'
 */
export const parseToDate = (timeValue) => {
  var result = "";
  var year = timeValue.substr(0, 4);
  var month = timeValue.substr(4, 2);
  var date = timeValue.substr(6, 2);
  var hour = timeValue.substr(8, 2);
  var minute = timeValue.substr(10, 2);
  var second = timeValue.substr(12, 2);
  result = year + "-" + month + "-" + date + " " + hour + ":" + minute + ":" + second;
  return result;
}
/**
 * 判断空值
 */
export const isEmpty = (keys) => {
  if (typeof keys === "string") {
    keys = keys.replace(/\"|&nbsp;|\\/g, '').replace(/(^\s*)|(\s*$)/g, "");
    if (keys == "" || keys == null || keys == "null" || keys === "undefined") {
      return true;
    } else {
      return false;
    }
  } else if (typeof keys === "undefined") {  // 未定义
    return true;
  } else if (typeof keys === "number") {
    return false;
  } else if (typeof keys === "boolean") {
    return false;
  } else if (typeof keys == "object") {
    if (JSON.stringify(keys) == "{}") {
      return true;
    } else if (keys == null) { // null
      return true;
    } else {
      return false;
    }
  }

  if (keys instanceof Array && keys.length == 0) {// 数组
    return true;
  }

}

/**
 * 返回两位的小数的字符串
 */
export const toFixedNum = (num) => {
  const tonum = Number(num).toFixed(2);
  return tonum;
}

export const showMessage = () => {
  this.$message({
    showClose: true,
    message: '对不起，您暂无此操作权限~',
    type: 'success'
  });
}

/**
 * 读取base64
 */
export const readFile = file => {
  console.log(file)
  //var file = this.files[0];
  //判断是否是图片类型
  if (!/image\/\w+/.test(file.raw.type)) {
    alert("只能选择图片");
    return false;
  }
  var reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = function (e) {
    var filedata = {
      filename: file.name,
      filebase64: e.target.result
    }
    alert(e.target.result)
  }
}

/**
 * 动态插入css
 */
export const loadStyle = url => {
  const link = document.createElement('link')
  link.type = 'text/css'
  link.rel = 'stylesheet'
  link.href = url
  const head = document.getElementsByTagName('head')[0]
  head.appendChild(link)
}
/**
 * 设置浏览器头部标题
 */
export const setTitle = (title) => {
  title = title ? `${title}` : ''
  window.document.title = title
}

export const param2Obj = url => {
  const search = url.split('?')[1]
  if (!search) {
    return {}
  }
  return JSON.parse('{"' + decodeURIComponent(search).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"') + '"}')
}

//是否为正整数
export const isInteger = (s) => {
  var re = /^[0-9]+$/;
  return re.test(s)
}

export const setContentHeight = (that, ele, height) => {
  that.$nextTick(() => {
    ele.style.height = (document.body.clientHeight - height) + 'px'
  })
}

export const toChinesNum = (num) => {
  let changeNum = ['零', '一', '二', '三', '四', '五', '六', '七', '八', '九']; //changeNum[0] = "零"
  let unit = ["", "十", "百", "千", "万"];
  num = parseInt(num);
  let getWan = (temp) => {
    let strArr = temp.toString().split("").reverse();
    let newNum = "";
    for (var i = 0; i < strArr.length; i++) {
      newNum = (i == 0 && strArr[i] == 0 ? "" : (i > 0 && strArr[i] == 0 && strArr[i - 1] == 0 ? "" : (unit[i] == '十' && strArr[i] == 1 ? '' : changeNum[strArr[i]]) + (strArr[i] == 0 ? unit[0] : unit[i]))) + newNum;
    }
    return newNum;
  }
  let overWan = Math.floor(num / 10000);
  let noWan = num % 10000;
  if (noWan.toString().length < 4) noWan = "0" + noWan;
  return overWan ? getWan(overWan) + "万" + getWan(noWan) : getWan(num);
}

export const splitArr = (arr, num) => {
  var res = [];
  for (var i = 0, len = arr.length; i < len; i += num) {
    res.push(arr.slice(i, i + num));
  }
  return res
}
/**
 * 获取当天日期
 */
export const getCurDay = () => {
  var datetime = new Date();
  var year = datetime.getFullYear();
  var month = datetime.getMonth() + 1 < 10 ? "0" + (datetime.getMonth() + 1) : datetime.getMonth() + 1;
  var date = datetime.getDate() < 10 ? "0" + datetime.getDate() : datetime.getDate();
  return `${year}-${month}-${date}`
}

export function getOnlyId() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxx'.replace(/[xy]/g, c => {
    const r = (Math.random() * 16) | 0
    const v = c == 'x' ? r : (r & 0x3) | 0x8
    return v.toString(16)
  })
}

/**
 * 从html代码中匹配返回图片标签img的属性src的值的集合
 * @param htmlData
 * @return Array
 */
export function findAllImgSrcsFromHtml(htmlData) {
  const imgReg = /<img.*?(?:>|\/>)/gi // 匹配图片中的img标签
  const srcReg = /src=[\\'\\"]?([^\\'\\"]*)[\\'\\"]?/i // 匹配图片中的src

  let arr = htmlData.match(imgReg) // 筛选出所有的img
  let isOfficeWord = false
  if (arr == null) {
    const imgReg1 = /file:.*?(?:.png|.jpg)/gi // 匹配图片中的img标签
    arr = htmlData.match(imgReg1) // 筛选出所有的img
    if (arr && arr.length) isOfficeWord = true
  }
  if (!arr || (Array.isArray(arr) && !arr.length)) {
    return false
  }

  let srcArr = []
  if (isOfficeWord) {
    srcArr = arr
  } else {
    const matchReg = '(?<=file:).*?(?=(.jpg|.png|.jpeg|.gif|$))'
    for (let i = 0; i < arr.length; i++) {
      const src = arr[i].match(srcReg)
      // 获取图片地址
      srcArr.push(src.input.match(matchReg))
    }
  }

  return {
    isOfficeWord,
    srcArr
  }
}

/**
 * 从rtf内容中匹配返回图片数据的集合
 * @param rtfData
 * @return Array
 */
export function extractImageDataFromRtf(rtfData) {
  if (!rtfData) {
    return []
  }

  const regexPictureHeader = /{\\pict[\s\S]+?({\\\*\\blipuid\s?[\da-fA-F]+)[\s}]*/
  const regexPicture = new RegExp('(?:(' + regexPictureHeader.source + '))([\\da-fA-F\\s]+)\\}', 'g')
  const images = rtfData.match(regexPicture)
  const result = []

  if (images) {
    for (const image of images) {
      let imageType = false

      if (image.includes('\\pngblip')) {
        imageType = 'image/png'
      } else if (image.includes('\\jpegblip')) {
        imageType = 'image/jpeg'
      }

      if (imageType) {
        result.push({
          hex: image.replace(regexPictureHeader, '').replace(/[^\da-fA-F]/g, ''),
          type: imageType
        })
      }
    }
  }

  return result
}

/**
 * 十六进制转base64
 */
export function _convertHexToBase64(hexString) {
  return btoa(
    hexString
      .match(/\w{2}/g)
      .map(char => {
        return String.fromCharCode(parseInt(char, 16))
      })
      .join('')
  )
}

// base64转Blob
export function convertBase64UrlToBlob(urlData) {
  // 去掉url的头，并转换为byte
  var split = urlData.split(',')
  var bytes = window.atob(split[1])
  // 处理异常,将ascii码小于0的转换为大于0
  var ab = new ArrayBuffer(bytes.length)
  var ia = new Uint8Array(ab)
  for (var i = 0; i < bytes.length; i++) {
    ia[i] = bytes.charCodeAt(i)
  }
  return new Blob([ab], {type: split[0]})
}

