var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "home rflex" },
    [
      _c("left-menu"),
      _c(
        "div",
        {
          ref: "menu_right",
          staticClass: "menu_right wflex el-scrollbar",
          style: { left: _vm.sidebar.width + "px" },
        },
        [
          _c("head-nav"),
          _c(
            "div",
            { ref: "menu_content", staticClass: "menu_content" },
            [_c("bread"), _c("app-main")],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }