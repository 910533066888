<template>
  <header class="head-nav rflex " :style="{'width':headNavWidth+'px'}" id='header_container'>
    <!--        <div class="right-nav" ref="rightNav">-->
    <top-menu></top-menu>
    <div class="userinfo">
      <el-menu
          class="el-menu-demo"
          mode="horizontal"
      >
        <el-submenu index="2" popper-class="infoItem">
          <template slot="title">
            <div class='welcome'>
<!--              <span class="name">您好,</span>-->
              <span class='name avatarname'> {{ name }}</span>
            </div>
<!--            <img :src="avatar" class='avatar' alt="">-->
          </template>
<!--          <el-menu-item index="2-1" @click="setDialogInfo('info')"> 个人信息</el-menu-item>-->
<!--          <el-menu-item index="2-2" @click="setDialogInfo('pass')"> 修改信息</el-menu-item>-->
          <el-menu-item index="2-3" @click="setDialogInfo('logout')">退出</el-menu-item>
        </el-submenu>
      </el-menu>
    </div>
    <!--        </div>-->
  </header>
</template>

<script>
import {mapGetters} from "vuex";
import * as mUtils from '@/utils/mUtils'
import {setToken, getToken} from '@/utils/auth'
import store from "@/store";
import topMenu from "./topMenu1";
import wechatImg from "@/assets/img/wechat.jpg";
import qqImg from "@/assets/img/qq.png";
import logoImg from "@/assets/img/logo.png";
import chinaImg from "@/assets/img/china.svg";
import americaImg from "@/assets/img/america.svg";
import {github} from "@/utils/env";


export default {
  name: 'head-nav',
  data() {
    return {
      logo: logoImg,
      langLogo: americaImg,
      chinaImg: chinaImg,
      americaImg: americaImg,
      wechat: {
        wechatImg: wechatImg,
        isWechat: false
      },
      qq: {
        qqImg: qqImg,
        isQq: false,
      },
      github: github,
      menu: {
        userBgcolor: '#CFFB44'
      }
    }
  },
  components: {
    topMenu
  },
  computed: {
    ...mapGetters(['name', 'avatar', 'sidebar']),
    headNavWidth() {
      return document.body.clientWidth - this.sidebar.width
    }

  },
  created() {

  },
  mounted() {
  },
  methods: {
    showWechat() {
      this.wechat.isWechat = true;
    },
    hideWechat() {
      this.wechat.isWechat = false;
    },
    showQq() {
      this.qq.isQq = true;
    },
    hideQq() {
      this.qq.isQq = false;
    },
    logout() {
      this.$store.dispatch('LogOut').then(() => {
        window.localStorage.clear()
        localStorage.removeItem('erabbit-client-pc-store')
        location.reload();
      })
    },
    /**
     * 弹出框-修改密码或者系统设置
     * @param {string} cmditem 弹框类型
     */
    setDialogInfo(cmditem) {
      switch (cmditem) {
        case 'info':
          this.$router.push('/infoManage/infoShow/infoShow1');
          break;
        case 'pass':
          this.$router.push('/infoManage/infoModify/infoModify1');
          break;
        case 'logout':
          this.logout();
          break;
      }
    },
    // 切换语言
    changeLocale(type) {
      setToken('lang', type);
      this.$i18n.locale = type;
      if (type === 'en') {
        this.langLogo = this.americaImg;
      } else {
        this.langLogo = this.chinaImg;
      }
      setToken('langLogo', this.langLogo);
    }
  }
}
</script>

<style scoped lang='less'>
@headNavColor: #CFFB44;
@font-color: #595757;
//.right-nav{
//    display: flex;
//    flex: 1;
//    width:calc(100% - 180px);
//    padding-right: 15px;
//    justify-content: space-between;
//    box-shadow:0px 2px 5px 0px rgba(237,233,233,0.5);
//}
.head-nav {
  color: @font-color;
  font-family: PingFang HK;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 999;
  transition: width .2s;
  justify-content: space-between;
  height: 80px;
  box-sizing: border-box;
  background: #CFFB44 !important;
  .logout {
    vertical-align: middle;
    cursor: pointer;
  }
}

.middle {
  align-items: center;
  border: 1px solid;
}

.userinfo {
  //line-height: 80px;
  text-align: right;
  margin-right: 30px;
}

.avatar {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  vertical-align: middle;
  display: inline-block;
}

.langAvatar {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  vertical-align: middle;
  display: inline-block;
}


.welcome {
  display: inline-block;
  vertical-align: middle;
  padding: 0 5px;
  .name {
    color: @font-color;
    line-height: 20px;
    text-align: center;
    font-size: 12px;
  }

  .avatarname {
    color: @font-color;
    font-family: PingFang HK;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 120% */
  }
}

.username {
  cursor: pointer;
  margin-right: 5px;
}

.border {
  border: 1px solid;
}

ul.top-menu > li {
  position: relative;
}
/deep/.el-submenu__title {
  height: 80px !important;
  display: flex !important;
  align-items: center !important;
  background-color: @headNavColor !important;
}
/deep/.el-icon-arrow-down{
  font-size: 20px;
  display: inline-block;
}
/deep/.el-menu--horizontal>.el-submenu .el-submenu__icon-arrow {
  margin-top: 3px;
}
</style>
