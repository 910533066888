var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { ref: "menuTop", staticClass: "menu_top wflex rflex" },
    [
      _c(
        "el-menu",
        {
          staticClass: "el-menu-demo rflex el-scrollbar2 top-scrollbar2",
          attrs: {
            mode: "horizontal",
            "background-color": _vm.menuObj.bgColor,
            "text-color": _vm.menuObj.textColor,
            "active-text-color": _vm.menuObj.activeTextColor,
            "default-active": _vm.addRouters[0].path,
          },
        },
        [
          _vm._l(_vm.addRouters, function (item, index) {
            return [
              _c(
                "el-menu-item",
                {
                  key: index,
                  attrs: { index: item.path },
                  on: {
                    click: function ($event) {
                      return _vm.setLeftRouters(item)
                    },
                  },
                },
                [_vm._v("\n        " + _vm._s(item.meta.title) + "\n      ")]
              ),
            ]
          }),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }