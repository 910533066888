import router from './router'
import store from './store'
import NProgress from 'nprogress' // Progress 进度条
import('nprogress/nprogress.css')
import {getToken} from '@/utils/auth' // 验权(获取)
import {
  setTitle
} from '@/utils/mUtils' // 设置浏览器头部标题

const whiteList = ['/login'] // 不重定向白名单

router.beforeEach((to, from, next) => {
  NProgress.start()
  // 点击登录时，拿到了token并存入了sessionStorage,保证页面刷新时,始终可以拿到token
  if (getToken('Token')) {
    if (to.path === '/login') {
      next({path: '/'})
      NProgress.done()
    } else {
      // // 用户登录成功之后，每次点击路由都进行了角色的判断;
      // if (store.getters.roles.length === 0) {
      //   store.commit("SET_ROLES", ['admin']);
      //   store.dispatch('GenerateRoutes', {"roles": ['admin']}).then(() => { // 根据roles权限生成可访问的路由表
      //     if (store.getters.addRouters.length) {
      //       for (let i = 0; i < store.getters.addRouters.length; i++) {
      //         router.addRoute(store.getters.addRouters[i]) // 动态添加可访问权限路由表
      //       }
      //     }
      //     // router.addRoutes(store.getters.addRouters) // 动态添加可访问权限路由表
      //     next({...to, replace: true}) // hack方法 确保addRoutes已完成
      //   })
      // } else {
      //   store.commit('SET_ACTIVEMENU', to.path)
      //   next()
      // }
      store.commit('SET_ACTIVEMENU', to.path)
        next()
        NProgress.done()
    }
  } else {
    if (whiteList.indexOf(to.path) !== -1) {
      // 点击退出时,会定位到这里
      next()
      NProgress.done()
    } else {
      next('/login')
      NProgress.done()
    }
  }
})

router.afterEach(() => {
  NProgress.done()
  setTimeout(() => {
    setTitle('趣e城后台管理系统')
  }, 0)
})
