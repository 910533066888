import Vue from 'vue'
import App from './App'
import router from './router'
import store from './store/'
// 'development',use package;'production':use cdn;
import ElementUI from 'element-ui'
Vue.use(ElementUI, { size: 'mini'});
import('element-ui/lib/theme-chalk/index.css')

import './components/iconSvg'

import '@/permission' // permission control

// import '@/mockjs'; //  mock数据
import moment from 'moment'; //导入模块
// moment.locale('zh-cn'); //设置语言 或 moment.lang('zh-cn');
// i18n国际化
import i18n from "@/lang";

import vuescroll from 'vuescroll'

import VueLodash from 'vue-lodash';
import lodash from 'lodash';
import 'amfe-flexible/index.js'
function px2rem(px) {
  if (/%/gi.test(px)) {
    return px
  } else {
    return parseFloat(px) / 192 + 'rem'
  }
}
Vue.use(VueLodash, { lodash: lodash });

Vue.use(vuescroll, {
  ops: {
    mode: 'slide',
    bar: {
      background: '#cacaca',
      opacity: 1,
      size: '5px'
    },
    scrollPanel: {
      scrollingX: false,
      scrollingY: true
    }
  },
  name: 'myScroll'
})
Vue.prototype.$px2rem = px2rem
Vue.prototype.$moment = moment;//赋值使用
// // 分享功能集合
// import { shareConfig } from './utils/share';
// Vue.prototype.shareConfig = shareConfig;

Vue.config.silent = true

// 字体图标线上地址,在index中使用cdn载入;
//at.alicdn.com/t/font_1258069_e40c6mwl0x8.js

new Vue({
  router,
  store,
  i18n,  // 便于可以直接在组件中通过this.$i18n使用，也可以按需引用
  render: h => h(App),
}).$mount('#app')
