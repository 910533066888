<template>
  <div class="home rflex">
    <left-menu></left-menu>
    <div class="menu_right wflex el-scrollbar" ref="menu_right" :style="{left:sidebar.width+'px'}">
      <head-nav></head-nav>
      <div class="menu_content" ref="menu_content">
        <bread></bread>
        <app-main></app-main>
      </div>
    </div>
  </div>
</template>
<script>
import AppMain from "./appMain.vue";
import {mapState, mapGetters} from 'vuex'
import HeadNav from './headNav.vue';
import LeftMenu from './leftMenu.vue';
import Bread from './bread.vue';

export default {
  name: 'home',
  data() {
    return {}
  },
  computed: {
    ...mapGetters(['sidebar']),
  },
  components: {
    HeadNav,
    LeftMenu,
    Bread,
    AppMain
  },
  created() {
  },
  mounted() {
  },
  watch: {}
}
</script>
<style scoped lang='less'>
.home {
  .menu_right {
    overflow: auto;
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    background: #F6F7FC;

    .menu_content {
      position: relative;
      margin-top: 80px;
      //width: calc(100vw - 255px);
      background: #FAFAFA;
    }

  }
}
</style>
