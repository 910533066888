var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "header",
    {
      staticClass: "head-nav rflex",
      style: { width: _vm.headNavWidth + "px" },
      attrs: { id: "header_container" },
    },
    [
      _c("top-menu"),
      _c(
        "div",
        { staticClass: "userinfo" },
        [
          _c(
            "el-menu",
            { staticClass: "el-menu-demo", attrs: { mode: "horizontal" } },
            [
              _c(
                "el-submenu",
                { attrs: { index: "2", "popper-class": "infoItem" } },
                [
                  _c("template", { slot: "title" }, [
                    _c("div", { staticClass: "welcome" }, [
                      _c("span", { staticClass: "name avatarname" }, [
                        _vm._v(" " + _vm._s(_vm.name)),
                      ]),
                    ]),
                  ]),
                  _c(
                    "el-menu-item",
                    {
                      attrs: { index: "2-3" },
                      on: {
                        click: function ($event) {
                          return _vm.setDialogInfo("logout")
                        },
                      },
                    },
                    [_vm._v("退出")]
                  ),
                ],
                2
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }