export const topRouterMap = [
  {
    'parentName': 'slideShow',
    'data': [
      {
        path: 'slideShowIndex',
        name: 'slideShowIndex',
        meta: {
          title: '列表',
          icon: 'fa-asterisk',
          routerType: 'topmenu'
        },
        component: () => import('@/page/home/slideShow/slideShowIndex')
      },
      {
        path: 'slideShowAdd',
        name: 'slideShowAdd',
        meta: {
          title: '添加',
          icon: 'fa-asterisk',
          routerType: 'topmenu'
        },
        component: () => import('@/page/home/slideShow/slideShowAdd')
      }
    ]
  },
  {
    'parentName': 'bootAdvertising',
    'data': [
      {
        path: 'bootAdvertising',
        name: 'bootAdvertising',
        meta: {
          title: '列表',
          icon: 'fa-asterisk',
          routerType: 'topmenu'
        },
        component: () => import('@/page/home/bootAdvertising/index')
      },
      {
        path: 'addAdvertising',
        name: 'addAdvertising',
        meta: {
          title: '添加',
          icon: 'fa-asterisk',
          routerType: 'topmenu'
        },
        component: () => import('@/page/home/bootAdvertising/addAdvertising')
      }
    ]
  },
  {
    'parentName': 'rankingList',
    'data': [
      {
        path: 'rankingList',
        name: 'rankingList',
        meta: {
          title: '列表',
          icon: 'fa-asterisk',
          routerType: 'topmenu'
        },
        component: () => import('@/page/home/rankingList/index')
      },
      {
        path: 'addRanking',
        name: 'addRanking',
        meta: {
          title: '添加',
          icon: 'fa-asterisk',
          routerType: 'topmenu'
        },
        component: () => import('@/page/home/rankingList/addRanking')
      }
    ]
  },
  {
    'parentName': 'hotStory',
    'data': [
      {
        path: 'hotStoryIndex',
        name: 'hotStoryIndex',
        meta: {
          title: '列表',
          icon: 'fa-asterisk',
          routerType: 'topmenu'
        },
        component: () => import('@/page/home/hotStory/hotStoryIndex')
      },
      {
        path: 'hotStoryAdd',
        name: 'hotStoryAdd',
        meta: {
          title: '添加',
          icon: 'fa-asterisk',
          routerType: 'topmenu'
        },
        component: () => import('@/page/home/hotStory/hotStoryAdd')
      }
    ]
  },
  {
    'parentName': 'pushMessage',
    'data': [
      {
        path: 'pushMessageIndex',
        name: 'pushMessageIndex',
        meta: {
          title: '列表',
          icon: 'fa-asterisk',
          routerType: 'topmenu'
        },
        component: () => import('@/page/home/pushMessage/pushMessageIndex')
      },
      {
        path: 'pushMessageAdd',
        name: 'pushMessageAdd',
        meta: {
          title: '添加',
          icon: 'fa-asterisk',
          routerType: 'topmenu'
        },
        component: () => import('@/page/home/pushMessage/pushMessageAdd')
      }
    ]
  },
  {
    'parentName': 'sale',
    'data': [
      {
        path: 'sale',
        name: 'sale',
        meta: {
          title: '列表',
          icon: 'fa-asterisk',
          routerType: 'topmenu'
        },
        component: () => import('@/page/productList/sale/sale')
      },
      {
        path: 'release',
        name: 'release',
        meta: {
          title: '发布产品',
          icon: 'fa-asterisk',
          routerType: 'topmenu'
        },
        component: () => import('@/page/productList/sale/release')
      }
    ]
  },
  {
    'parentName': 'allOrder',
    'data': [
      {
        path: 'allOrder',
        name: 'allOrder',
        meta: {
          title: '列表',
          icon: 'fa-asterisk',
          routerType: 'topmenu'
        },
        component: () => import('@/page/productList/order/allOrder/index')
      },
      {
        path: 'detail',
        name: 'detail',
        meta: {
          title: '订单详情',
          icon: 'fa-asterisk',
          routerType: 'topmenu'
        },
        component: () => import('@/page/productList/order/allOrder/detail')
      }
    ]
  },
  {
    'parentName': 'story',
    'data': [
      {
        path: 'index',
        name: 'index',
        meta: {
          title: '列表',
          icon: 'fa-asterisk',
          routerType: 'topmenu'
        },
        component: () => import('@/page/story/index')
      },
      {
        path: 'roles',
        name: 'roles',
        meta: {
          title: '角色',
          icon: 'fa-asterisk',
          routerType: 'topmenu'
        },
        component: () => import('@/page/story/roles')
      },
      {
        path: 'addRoles',
        name: 'addRoles',
        meta: {
          title: '添加角色',
          icon: 'fa-asterisk',
          routerType: 'topmenu'
        },
        component: () => import('@/page/story/addRoles')
      },
      {
        path: 'clue',
        name: 'clue',
        meta: {
          title: '线索',
          icon: 'fa-asterisk',
          routerType: 'topmenu'
        },
        component: () => import('@/page/story/clue')
      },
      {
        path: 'addSlidebar',
        name: 'addSlidebar',
        meta: {
          title: '添加边栏',
          icon: 'fa-asterisk',
          routerType: 'topmenu'
        },
        component: () => import('@/page/story/addSlidebar')
      },
      {
        path: 'addClue',
        name: 'addClue',
        meta: {
          title: '添加新线索',
          icon: 'fa-asterisk',
          routerType: 'topmenu'
        },
        component: () => import('@/page/story/addClue')
      },
      {
        path: 'addSawPuzzleClue',
        name: 'addSawPuzzleClue',
        meta: {
          title: '拼图线索',
          icon: 'fa-asterisk',
          routerType: 'topmenu'
        },
        component: () => import('@/page/story/addSawPuzzleClue')
      },
      {
        path: 'survey',
        name: 'survey',
        meta: {
          title: '问卷',
          icon: 'fa-asterisk',
          routerType: 'topmenu'
        },
        component: () => import('@/page/story/survey')
      },
      {
        path: 'addSurveyQuestion',
        name: 'addSurveyQuestion',
        meta: {
          title: '添加问题',
          icon: 'fa-asterisk',
          routerType: 'topmenu'
        },
        component: () => import('@/page/story/addSurveyQuestion')
      },
      {
        path: 'votes',
        name: 'votes',
        meta: {
          title: '投票',
          icon: 'fa-asterisk',
          routerType: 'topmenu'
        },
        component: () => import('@/page/story/votes')
      },
      {
        path: 'addVotesQuestion',
        name: 'addVotesQuestion',
        meta: {
          title: '添加问题',
          icon: 'fa-asterisk',
          routerType: 'topmenu'
        },
        component: () => import('@/page/story/addVotesQuestion')
      },
      {
        path: 'npc',
        name: 'npc',
        meta: {
          title: 'NPC',
          icon: 'fa-asterisk',
          routerType: 'topmenu'
        },
        component: () => import('@/page/story/npc')
      },
      {
        path: 'addNPC',
        name: 'npcDetail',
        meta: {
          title: '设置NPC',
          icon: 'fa-asterisk',
          routerType: 'topmenu'
        },
        component: () => import('@/page/story/addNPC')
      }
    ]
  },
  {
    'parentName': 'npcManage',
    'data': [
      {
        path: 'npcManage',
        name: 'npcManage',
        meta: {
          title: '列表',
          icon: 'fa-asterisk',
          routerType: 'topmenu'
        },
        component: () => import('@/page/story/npcManage/npcManage')
      },
      {
        path: 'addNPC',
        name: 'addNPC',
        meta: {
          title: '添加NPC',
          icon: 'fa-asterisk',
          routerType: 'topmenu'
        },
        component: () => import('@/page/story/npcManage/addNPC')
      }
    ]
  },
  {
    'parentName': 'questionBank',
    'data': [
      {
        path: 'questionBank',
        name: 'questionBank',
        meta: {
          title: '列表',
          icon: 'fa-asterisk',
          routerType: 'topmenu'
        },
        component: () => import('@/page/story/questionBank/index')
      },
      {
        path: 'gapFillingQuestionBank',
        name: 'gapFillingQuestionBank',
        meta: {
          title: '填空题题库',
          icon: 'fa-asterisk',
          routerType: 'topmenu'
        },
        component: () => import('@/page/story/questionBank/gapFillingQuestionBank')
      },
      {
        path: 'choiceQuestionBank',
        name: 'choiceQuestionBank',
        meta: {
          title: '选择题题库',
          icon: 'fa-asterisk',
          routerType: 'topmenu'
        },
        component: () => import('@/page/story/questionBank/choiceQuestionBank')
      },
      {
        path: 'questionBankInfo',
        name: 'questionBankInfo',
        meta: {
          title: '题库列表',
          icon: 'fa-asterisk',
          routerType: 'topmenu'
        },
        component: () => import('@/page/story/questionBank/questionBankInfo')
      },
      {
        path: 'editQuestion',
        name: 'editQuestion',
        meta: {
          title: '新增/编辑',
          icon: 'fa-asterisk',
          routerType: 'topmenu'
        },
        component: () => import('@/page/story/questionBank/editQuestion')
      }
    ]
  },
  {
    'parentName': 'qm',
    'data': [
      {
        path: 'qmIndex',
        name: 'qmIndex',
        meta: {
          title: '列表',
          icon: 'fa-asterisk',
          routerType: 'topmenu'
        },
        component: () => import('@/page/user/qm/index')
      },
      {
        path: 'addQM',
        name: 'addQM',
        meta: {
          title: '添加趣盟伙伴',
          icon: 'fa-asterisk',
          routerType: 'topmenu'
        },
        component: () => import('@/page/user/qm/addQM')
      },
      {
        path: 'discount',
        name: 'discount',
        meta: {
          title: '商家优惠券列表',
          icon: 'fa-asterisk',
          routerType: 'topmenu'
        },
        component: () => import('@/page/user/qm/discount')
      },
      {
        path: 'edit',
        name: 'edit',
        meta: {
          title: '编辑',
          icon: 'fa-asterisk',
          routerType: 'topmenu'
        },
        component: () => import('@/page/user/qm/edit')
      },
      {
        path: 'viewCommission',
        name: 'viewCommission',
        meta: {
          title: '查看佣金',
          icon: 'fa-asterisk',
          routerType: 'topmenu'
        },
        component: () => import('@/page/user/qm/viewCommission')
      },
      {
        path: 'correlationProduct',
        name: 'correlationProduct',
        meta: {
          title: '关联产品',
          icon: 'fa-asterisk',
          routerType: 'topmenu'
        },
        component: () => import('@/page/user/qm/correlationProduct')
      }
    ]
  },
  {
    'parentName': 'footmarkBackground',
    'data': [
      {
        path: 'footmarkList',
        name: 'footmarkList',
        meta: {
          title: '列表',
          icon: 'fa-asterisk',
          routerType: 'topmenu'
        },
        component: () => import('@/page/user/footmarkBackground/index')
      },
      {
        path: 'addFootmark',
        name: 'addFootmark',
        meta: {
          title: '添加',
          icon: 'fa-asterisk',
          routerType: 'topmenu'
        },
        component: () => import('@/page/user/footmarkBackground/addFootmark')
      }
    ]
  },
  {
    'parentName': 'footmarkPoint',
    'data': [
      {
        path: 'pointList',
        name: 'pointList',
        meta: {
          title: '列表',
          icon: 'fa-asterisk',
          routerType: 'topmenu'
        },
        component: () => import('@/page/user/footmarkPoint/index')
      },
      {
        path: 'addFootmarkPoint',
        name: 'addFootmarkPoint',
        meta: {
          title: '添加',
          icon: 'fa-asterisk',
          routerType: 'topmenu'
        },
        component: () => import('@/page/user/footmarkPoint/addFootmark')
      }
    ]
  },
  {
    'parentName': 'questionList',
    'data': [
      {
        path: 'questionList',
        name: 'questionList',
        meta: {
          title: '列表',
          icon: 'fa-asterisk',
          routerType: 'topmenu'
        },
        component: () => import('@/page/home/question/questionList')
      },
      {
        path: 'questionAdd',
        name: 'questionAdd',
        meta: {
          title: '添加',
          icon: 'fa-asterisk',
          routerType: 'topmenu'
        },
        component: () => import('@/page/home/question/questionAdd')
      }
    ]
  },
  {
    'parentName': 'userMember',
    'data': [
      {
        path: 'userMemberList',
        name: 'userMemberList',
        meta: {
          title: '列表',
          icon: 'fa-asterisk',
          routerType: 'topmenu'
        },
        component: () => import('@/page/member/userMemberList')
      },
      {
        path: 'addUserMember',
        name: 'addUserMember',
        meta: {
          title: '添加',
          icon: 'fa-asterisk',
          routerType: 'topmenu'
        },
        component: () => import('@/page/member/addUserMember')
      }
    ]
  }
]


