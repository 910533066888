<template>
  <div class="menu_top wflex rflex" ref="menuTop">
    <el-menu
        mode="horizontal"
        class="el-menu-demo rflex el-scrollbar2 top-scrollbar2"
        :background-color="menuObj.bgColor"
        :text-color="menuObj.textColor"
        :active-text-color="menuObj.activeTextColor"
        :default-active="addRouters[0].path"
    >
      <template v-for="(item,index) in addRouters">
        <el-menu-item :index="item.path" :key="index" @click="setLeftRouters(item)">
          {{ item.meta.title }}
        </el-menu-item>
      </template>
    </el-menu>
  </div>
</template>

<script>
import {mapGetters, mapMutations} from 'vuex'

export default {
  name: 'top-menu1',
  data() {
    return {
      menuObj: {
        bgColor: '#CFFB44',
        textColor: '#595757',
        activeTextColor: '#A37DE1',
      },
    }
  },
  computed: {
    ...mapGetters(['topRouters', 'addRouters'])
  },
  created() {
    this.setLeftRouters(this.addRouters[0])
    // this.SET_LEFT_ROUTERS(this.addRouters[0].children)
    console.log(this.addRouters, 'permission_routers')
    // this.setLeftInnerMenu();  // 针对刷新页面时，也需要加载顶部菜单
  },
  mounted() {
  },
  methods: {
    ...mapMutations(['SET_LEFT_ROUTERS']),
    setLeftRouters(item) {
      this.SET_LEFT_ROUTERS(item.children)
    },
    getPath() {
      // this.setLeftInnerMenu();
    },
  },
  // watch: {
  //   "$route": "getPath"
  // }
}

</script>

<style lang="less" scoped>
@headNavColor: #CFFB44;
@font-color: #595757;
@menuActiveTextColor: #A37DE1;
.menu_top {
  width: calc(100vw - 255px);

  .el-menu-demo {
    overflow-y: hidden;
    flex: 1;
  }

  .el-menu-item {
    display: flex;
    align-items: center;
    height: 100%;
    background: none;
    color: @font-color;
    font-family: PingFang HK;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    margin: 0 20px;
    border: none;
  }
  .el-menu--horizontal>.el-menu-item.is-active {
    border: none;
  }

  /deep/.el-menu-item:focus, .el-menu-item:hover {
    outline: 0;
    background: none !important;
    color: @menuActiveTextColor;
  }

}
</style>
