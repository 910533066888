import Vue from 'vue'
import Vuex from 'vuex'

import user from './modules/user'
import permission from './modules/permission'
import menu from './modules/menu'
import createPersistedstate from 'vuex-persistedstate'
Vue.use(Vuex)
export default new Vuex.Store({
    modules: {
        user,
        permission,
        menu
    },
  plugins: [
    createPersistedstate({
      key: 'erabbit-client-pc-store',
      paths: ['user', 'permission', 'menu' ]
    })
  ]
});

