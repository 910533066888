import axios from 'axios'
import { Message, MessageBox } from 'element-ui'
import store from '../store'
import {processParamsOfGet} from '@/utils/validate'
import { getToken } from '@/utils/auth'
import qs from 'qs'
// 创建axios实例
let service = axios.create({
  baseURL: process.env.VUE_APP_URL, // api的base_url
  timeout: 1000 * 60 * 60 // 请求超时时间
})
let type
// request拦截器
service.interceptors.request.use(config => {
  if (getToken('Token')) {
    config.headers = {
      'Authorization' : getToken('Token'), //携带权限参数
     };
  }
  type = config.type
  if (['post', 'put', 'delete'].includes(config.method.toLowerCase())) {
    if (config.type === 'json') {
      config.headers['Content-Type'] = 'application/json;charset=UTF-8'
      // config.headers['Content-Disposition'] = 'attachment'
      config.data = JSON.stringify(config.data)
    } else if (config.type === 'oss-form-data') {
      config.baseURL = ''
      config.headers['Content-Type'] = 'multipart/form-data'
    } else if (config.type === 'form-data') {
      config.headers['Content-Type'] = 'multipart/form-data'
    } else if (config.type === 'download') {
      config.headers['Content-Type'] = 'application/json;charset=UTF-8'
      config.data = JSON.stringify(config.data)
    } else {
      config.headers['Content-Type'] = 'application/x-www-form-urlencoded'
      config.data = qs.stringify(config.data)
    }
  } else if (['get'].includes(config.method.toLowerCase())) {
    // config.headers['Content-Type'] = 'application/octet-stream'
    config.headers['Content-Type'] = 'application/x-www-form-urlencoded'
    if (config.data) {
      if (config.type === 'obj') {
        // 如果请求参数中对后端是map结构不要用这个
        const params = processParamsOfGet(config.data)
        config.data = qs.stringify(params)
      } else {
        // 如果请求参数中对后端是map结构用这个
        config.data = qs.stringify(config.data)
      }
      config.url = config.url + '?' + config.data
    }
  }
  return config
}, error => {
  Promise.reject(error)
})
// respone拦截器
service.interceptors.response.use(
  response => {
    if (type === 'download' || type === 'download2') {
      if (response.status == 200) {
        return response.data
      }
    }
   // oss上传完文件，返回的状态是204，所以要单独处理返回结果 start
    if (response.status === 204) {
      return {
        code: '200',
        data: null,
        msg: '上传成功'
      }
    }
    // end
   /**
    * code:200,接口正常返回;
    */
    const res = response.data
    if (res.code !== '200' && res.code !== 204) {
      Message({
        message: res.msg,
        type: 'error',
        duration: 5 * 1000
      })
      if (res.code === '401') {
          MessageBox.confirm('用户信息已失效，您可以取消继续留在该页面，或者重新登录', '确定登出', {
            confirmButtonText: '重新登录',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            store.dispatch('LogOut').then(() => {
              location.reload()// 为了重新实例化vue-router对象 避免bug
            })
          })
      }
      return response.data
      // return Promise.reject('error')
    } else { // res.code === 200,正常返回数据
        return response.data
    }
  },
  error => {
    Message({
      message: error.msg,
      type: 'error',
      duration: 5 * 1000
    })
    return Promise.reject(error)
  }
)

export default service
