<template>
  <div class="menu_left cflex" :style="{width:sidebar.width+'px'}">
    <div class="menu_page_top rflex">
      <!--            <img :class='["logo",{"closeLogo":!sidebar.opened}]' :src="logo" alt="趣 e 城" >-->
      <span class='title' v-show="sidebar.opened">趣e城 后台管理系统</span>
    </div>
    <div class="menu_page_bottom is-scroll-left" v-if="isShow">
      <el-menu
          mode="vertical"
          theme="dark"
          :show-timeout="200"
          :collapse="isCollapse"
          :default-active="defaultActive"
          :background-color="menuObj.bgColor"
          :text-color="menuObj.textColor"
          :active-text-color="menuObj.activeTextColor"
          :style="{width:sidebar.width+'px'}"
      >
        <template v-for="(item,index) in leftRouters">
          <!--表示 有一级菜单-->
          <router-link v-if="!item.children" :to="item.path" :key="index">
            <el-menu-item class="dropItem"
                          :index="item.path"
            >
              <icon-svg v-if="item.meta.icon" :icon-class="item.meta.icon"/>
              <span v-if="item.meta.title" slot="title">{{ item.meta.title }}</span>
            </el-menu-item>
          </router-link>
          <router-link v-if="item.children && item.meta.routerType === 'leftmenu'" :to="item.path+'/'+item.children[0].path" :key="index">
            <el-menu-item class="dropItem"
                          :index="item.path+'/'+item.children[0].path"
            >
              <icon-svg v-if="item.meta.icon" :icon-class="item.meta.icon"/>
              <span v-if="item.meta.title" slot="title">{{ item.meta.title }}</span>
            </el-menu-item>
          </router-link>
          <template v-if="item.children && item.meta.routerType === 'iframemenu'">
            <!--表示 有二级或者多级菜单 -->
            <el-submenu :index="item.path" class="dropItem">
              <template slot="title">
                <icon-svg v-if="item.meta.icon" :icon-class="item.meta.icon"/>
                <span v-if="item.meta.title" slot="title">{{ item.meta.title }}</span>
              </template>
              <!--直接定位到子路由上，子路由也可以实现导航功能-->
              <router-link v-for="(citem,cindex) in item.children" :to="getIindex(citem,item,cindex)" :key="cindex">
                <el-menu-item
                  class="dropItem"
                  :index="getIindex(citem,item,cindex)">
                  <span slot="title">
                     <icon-svg v-if="citem.meta.icon" :icon-class="citem.meta.icon"/>
                    {{ citem.meta.title }}
                  </span>
                </el-menu-item>
              </router-link>
            </el-submenu>
          </template>
        </template>
<!--                <template v-for="(item,index) in permission_routers">-->
<!--                  &lt;!&ndash;表示 有一级菜单&ndash;&gt;-->
<!--                  <router-link v-if="!item.hidden && item.noDropdown" :to="item.path+'/'+item.children[0].path" :key="index">-->
<!--                    <el-menu-item class="dropItem"-->
<!--                                  :index="item.path+'/'+item.children[0].path"-->
<!--                    >-->
<!--                      <icon-svg v-if="item.meta.icon" :icon-class="item.meta.icon"/>-->
<!--                      <span v-if="item.meta.title" slot="title">{{ $t(`commons.${item.name}`) }}</span>-->
<!--                    </el-menu-item>-->
<!--                  </router-link>-->

<!--                  &lt;!&ndash;表示 有二级或者多级菜单 &ndash;&gt;-->
<!--                  <el-submenu v-if="item.children  && item.children.length >= 1 && !item.hidden && !item.noDropdown"-->
<!--                              :index="item.path" :key="index">-->
<!--                    <template slot="title">-->
<!--                      <icon-svg v-if="item.meta.icon" :icon-class="item.meta.icon"/>-->
<!--                      <span v-if="item.meta.title" slot="title">{{ $t(`commons.${item.name}`) }}</span>-->
<!--                    </template>-->
<!--                    &lt;!&ndash;直接定位到子路由上，子路由也可以实现导航功能&ndash;&gt;-->
<!--                    <router-link v-for="(citem,cindex) in item.children" :to="getIindex(citem,item,cindex)" :key="cindex">-->
<!--                      <el-menu-item-->
<!--                          v-if="citem.meta.routerType != 'topmenu' && citem.meta.title"-->
<!--                          :index="getIindex(citem,item,cindex)">-->
<!--                        <span slot="title"> {{ $t(`commons.${citem.name}`) }}</span>-->
<!--                      </el-menu-item>-->
<!--                    </router-link>-->
<!--                  </el-submenu>-->
<!--                </template>-->
      </el-menu>
    </div>
  </div>
</template>

<script>
import {mapGetters} from 'vuex'
import * as mUtils from "@/utils/mUtils";
import logoImg from "@/assets/img/logo.png";
import store from "@/store";


export default {
  name: "left-Menu",
  data() {
    return {
      menuObj: {
        bgColor: '',
        textColor: '#595757',
        activeTextColor: '#A37DE1',
      },
      logo: logoImg,
      defaultActive: '',
      isShow: true
    };
  },
  computed: {
    ...mapGetters([
      'leftRouters',
      'permission_routers',
      'isCollapse',
      'sidebar',
      'menuIndex',
      'activeMenu'
    ]),
  },
  watch: {
    leftRouters: {
      handler(newVal) {
        if (newVal) {
          console.log(this.activeMenu, 'activeMenu==')
          this.setDefaultActive(newVal)
        }
      },
      deep: true,
      immediate: true
    },
    "$route": "setRoles"
  },
  created() {},
  mounted() {
  },
  methods: {
    setDefaultActive(newVal) {
      this.setRoles()
      // if (this.activeMenu) {
      //   this.defaultActive = this.activeMenu
      // } else {
        if (!newVal[0].children || !newVal[0].children.length) {
          this.defaultActive = newVal[0].path
        } else {
          if (newVal[0].meta.titleList && newVal[0].meta.titleList.length) {
            this.defaultActive = newVal[0].path + '/' + newVal[0].meta.titleList[0].path
          } else {
            this.defaultActive = newVal[0].children[0].path
          }
        }
      // }
      this.isShow = false
      this.$nextTick(() => {
        this.isShow = true
      })
      this.$router.push({path: this.defaultActive})
    },
    getIindex(citem, item, cindex) {
      const path = (citem.meta.titleList) ?  citem.path + '/' + citem.meta.titleList[0].path : citem.path;
      // store.commit('SET_ACTIVEMENU', path)
      return path
    },
    // 跳转路由时重置角色，路由守卫重新添加路由，解决刷新页面路由丢失（空白页面）问题
    setRoles() {
      store.commit("SET_ROLES", []);
    }
  }
};
</script>


<style lang="less" scoped>
@left-bgColor: #fff; // 左侧菜单背景颜色;
@icon-link: #FF6C60;
@headNavColor: #CFFB44;
@font-color: #595757;
.menu_left {
  width: 255px;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
}

.menu_page_top {
  color: @font-color;
  font-family: PingFang HK;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  width: 100%;
  height: 80px;
  align-items: center;
  justify-content: space-around;
  //text-transform: uppercase;
  box-sizing: border-box;
  box-shadow: 0px 2px 5px 0px rgba(230, 224, 224, 0.5);
  background: @headNavColor;

  .logo {
    height: 36px;
    width: 36px;
    vertical-align: middle;
    display: inline-block;
  }

  .closeLogo {
    width: 30px;
    height: 30px;
  }

  .title {
    font-size: 22px;

    i {
      color: #FF6C60;
    }
  }
}

.menu_page_bottom {
  width: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  flex: 1;
  margin-top: 3px;
  z-index: 10;
  box-shadow: 0 0 10px 0 rgba(230, 224, 224, 0.5);


  .el-menu-item {
    width: 100%;
    color: #595757;
    font-family: PingFang HK;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    padding: 0 50px !important;
    margin: 20px 0;
  }

  /deep/.el-submenu__title {
    width: 100%;
    color: #595757;
    font-family: PingFang HK;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    padding: 0 45px !important;
  }
  .el-submenu .el-menu-item {
    width: 100%;
    color: #595757;
    font-family: PingFang HK;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    padding: 0 80px !important;
  }

  /deep/ .el-submenu__icon-arrow, /deep/ .el-icon-arrow-down {
    font-size: 20px;
    font-weight: 600;
    color: #595757;
    //width: 100%;
    //padding: 0 20px !important;
  }
}
</style>
