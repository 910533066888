import request from '@/utils/axios'

//登录
export function login(params) {
  return request({
    url: '/miqu/miniprogram/api/v1/account/admin/login',
    method: 'post',
    data:params,
    type: 'json'
  })
}

// 导出优惠券
export function exportCoupon(params) {
  return request({
    url: '/miqu/api/v1/coupon/export',
    method: 'post',
    data:params,
    responseType: 'blob',
    type: 'download2'
  })
}
