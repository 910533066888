<template>
  <div class="content">
    <transition name="fade" mode="out-in">
      <router-view></router-view>
    </transition>
  </div>
</template>
<script>
export default {
  name: '',
  data() {
    return {}
  },
  methods: {},
  created() {

  },
  mounted() {

  }
}
</script>

<style scoped lang='less'>
.content {
  width: 100%;
  height: calc(100vh - 130px);
  //overflow-y: scroll;
  //overflow-x: hidden;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity .3s
}

.fade-enter,
.fade-leave-active {
  opacity: 0
}
</style>
