<template>
  <section class="app-main">
    <transition name="fade" mode="out-in">
        <router-view />
    </transition>
  </section>
</template>

<script>
import Bread from './bread.vue';
export default {
  name: 'AppMain',
  components: {
    Bread
  },
}
</script>

<style lang="less" scoped>
.app-main {
  width: 100%;
  height: calc(100vh - 130px);
  overflow-y: scroll;
  overflow-x: hidden;
}

.fixed-header + .app-main {
  padding-top: 50px;
}

//.hasTagsView {
//  .app-main {
//    /* 84 = navbar + tags-view = 50 + 34 */
//    min-height: calc(100vh - 84px);
//  }
//
//  .fixed-header+.app-main {
//    padding-top: 84px;
//  }
//}
</style>

<style lang="less">
// fix css style bug in open el-dialog
.el-popup-parent--hidden {
  .fixed-header {
    padding-right: 15px;
  }
}
</style>
